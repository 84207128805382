import {EEntityType} from '@mcal/core';
import type {ILinksMap} from '@mcal/core-react-web';
import {MainLayout, Text} from '@mcal/core-react-web';
import type {FC} from 'react';
import {lazy} from 'react';
import {createBrowserRouter, useRouteError} from 'react-router-dom';
import {
    PLATFORM_MANAGEMENT_LINKS,
    SERVICE_COMPANY_LINKS,
    SITE_LINKS
} from './links.js';

const Settings = lazy(() =>
    import('@mcal/core-react-web/views/settings').then(({Settings}) => ({
        default: Settings
    }))
);

const CreateServiceCompany = lazy(() =>
    import('@mcal/core-react-web/views/create-service-company').then(
        ({CreateServiceCompany}) => ({
            default: CreateServiceCompany
        })
    )
);

const CreateTechnicians = lazy(() =>
    import('@mcal/core-react-web/views/create-technicians').then(
        ({CreateTechnicians}) => ({
            default: CreateTechnicians
        })
    )
);

const LogIn = lazy(() =>
    import('@mcal/core-react-web/views/log-in').then(({LogIn}) => ({
        default: LogIn
    }))
);

const SignUp = lazy(() =>
    import('@mcal/core-react-web/views/sign-up').then(({SignUp}) => ({
        default: SignUp
    }))
);

const ForgotPassword = lazy(() =>
    import('@mcal/core-react-web/views/forgot-password').then(
        ({ForgotPassword}) => ({
            default: ForgotPassword
        })
    )
);

const PlatformManagementExplore = lazy(() =>
    import('@mcal/core-react-web/views/platform-management-explore').then(
        ({PlatformManagementExplore}) => ({
            default: PlatformManagementExplore
        })
    )
);

const PMServicesCompanies = lazy(() =>
    import('@mcal/core-react-web/views/pm-services-companies').then(
        ({PMServicesCompanies}) => ({
            default: PMServicesCompanies
        })
    )
);

const ServiceCompanyOverview = lazy(() =>
    import('@mcal/core-react-web/views/service-company-overview').then(
        ({ServiceCompanyOverview}) => ({
            default: ServiceCompanyOverview
        })
    )
);

const Users = lazy(() =>
    import('@mcal/core-react-web/views/users').then(({Users}) => ({
        default: Users
    }))
);

const Technicians = lazy(() =>
    import('@mcal/core-react-web/views/technicians').then(({Technicians}) => ({
        default: Technicians
    }))
);

const Sites = lazy(() =>
    import('@mcal/core-react-web/views/sites').then(({Sites}) => ({
        default: Sites
    }))
);

const Teams = lazy(() =>
    import('@mcal/core-react-web/views/teams').then(({Teams}) => ({
        default: Teams
    }))
);

const ServiceCompanySettings = lazy(() =>
    import('@mcal/core-react-web/views/service-company-settings').then(
        ({ServiceCompanySettings}) => ({
            default: ServiceCompanySettings
        })
    )
);

const Elevators = lazy(() =>
    import('@mcal/core-react-web/views/elevators').then(({Elevators}) => ({
        default: Elevators
    }))
);

const Site = lazy(() =>
    import('@mcal/core-react-web/views/site').then(({Site}) => ({
        default: Site
    }))
);

const SiteSettings = lazy(() =>
    import('@mcal/core-react-web/views/site-settings').then(
        ({SiteSettings}) => ({
            default: SiteSettings
        })
    )
);

const ElevatorDetails = lazy(() =>
    import('@mcal/core-react-web/views/elevator-details').then(
        ({ElevatorDetails}) => ({
            default: ElevatorDetails
        })
    )
);

const Error: FC = () => {
    const error = useRouteError();

    return (
        <>
            <Text variant={'h1'}>{'ERROR'}</Text>

            <Text variant={'monospace'}>
                <span style={{whiteSpace: 'pre'}}>
                    {JSON.stringify(error, null, 2)}
                </span>
            </Text>
        </>
    );
};

const linksMap: ILinksMap = {
    sites: SITE_LINKS,
    serviceCompanies: SERVICE_COMPANY_LINKS,
    home: PLATFORM_MANAGEMENT_LINKS
};

const router = createBrowserRouter([
    {
        path: '/',
        element: <MainLayout linksMap={linksMap} />,
        errorElement: <Error />,
        children: [
            {
                path: '/',
                element: <PlatformManagementExplore />,
                errorElement: <Error />
            },
            {
                path: '/service-companies',
                element: <PMServicesCompanies />,
                errorElement: <Error />,
                children: [
                    {
                        path: '/service-companies/:serviceCompanyId',
                        errorElement: <Error />,
                        children: [
                            {
                                path: '/service-companies/:serviceCompanyId/overview',
                                element: <ServiceCompanyOverview />,
                                errorElement: <Error />
                            },
                            {
                                path: '/service-companies/:serviceCompanyId/sites',
                                element: <Sites />,
                                errorElement: <Error />
                            },
                            {
                                path: '/service-companies/:serviceCompanyId/teams',
                                element: (
                                    <Teams
                                        entityType={EEntityType.ServiceCompany}
                                    />
                                ),
                                errorElement: <Error />
                            },
                            {
                                path: '/service-companies/:serviceCompanyId/settings',
                                element: <ServiceCompanySettings />,
                                errorElement: <Error />
                            }
                        ]
                    }
                ]
            },
            {
                path: '/site/:siteId',
                errorElement: <Error />,
                children: [
                    {
                        path: '/site/:siteId/overview',
                        element: <Site />,
                        errorElement: <Error />
                    },
                    {
                        path: '/site/:siteId/elevators',
                        element: <Elevators />,
                        errorElement: <Error />,
                        children: [
                            {
                                path: ':elevatorId',
                                element: <ElevatorDetails />
                            },
                            {
                                path: ':elevatorId/teams',
                                element: (
                                    <Teams entityType={EEntityType.Elevator} />
                                ),
                                errorElement: <Error />
                            }
                        ]
                    },
                    {
                        path: '/site/:siteId/teams',
                        element: <Teams entityType={EEntityType.Site} />,
                        errorElement: <Error />
                    },
                    {
                        path: '/site/:siteId/settings',
                        element: <SiteSettings />,
                        errorElement: <Error />
                    }
                ]
            },
            {
                path: '/settings',
                element: <Settings />,
                errorElement: <Error />
            },
            {
                path: '/users',
                element: <Users />,
                errorElement: <Error />
            },
            {
                path: '/technicians',
                element: <Technicians />,
                errorElement: <Error />
            },
            {
                path: '/create-service-company',
                element: <CreateServiceCompany />,
                errorElement: <Error />
            },
            {
                path: '/create-technicians',
                element: <CreateTechnicians />,
                errorElement: <Error />
            }
        ]
    },
    {
        path: '/log-in',
        element: <LogIn />,
        errorElement: <Error />
    },
    {
        path: '/sign-up',
        element: <SignUp />,
        errorElement: <Error />
    },
    {
        path: '/reset-password',
        element: <ForgotPassword />,
        errorElement: <Error />
    }
]);

export {router};
